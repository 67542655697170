const CallTracker = settings => {
	const module = {
		settings,
		init: function() {
			if (Object.keys(settings).length === 0 ) {
				return;
			}

			this.updatePhones();
		},
		getCallTrackingPhone: function() {
			const sourceCookie = TRMK_SOURCE_TRACKER.getSourceData();
			const {triggers} = this.settings;
			let targetPhone = '';

			// Loop through triggers
			Object.keys(triggers).forEach(trigger => {
				if (sourceCookie.hasOwnProperty(trigger)) {
					Object.keys(triggers[trigger]).forEach(key => {
						if (key === sourceCookie[trigger]) {
							targetPhone = triggers[trigger][key];
						}
					});
				}
			});

			return targetPhone;
		},
		updatePhones: function() {
			const phone = this.getCallTrackingPhone();

			if (phone === '') {
				return;
			}

			$(`a:contains(${this.settings.swap})`).each(function() {
				$(this).attr('href', `tel:${phone.replace(/\D/g, '')}`).text(phone);
			});
		}
	}

	// Self init
	module.init();

	return module;
}

/**
 * Jquery document ready.
 * Initialize CallTracker library.
 */
$(document).on('trmk:source-tracker-initialized', () => {
	const ct = new CallTracker(window.TRMK_CALL_TRACKING_VARS || {});
});
